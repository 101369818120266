
// メタデータ
export const bustArr= ["未設定", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]
export const rootUri = "https://tulip-girls.net"
export const apiRoot = "https://api.tulip-girls.net/api"
export const diaryApiRoot = "https://api.marineblue-g.com/api"

// ヘブンの店舗topページ 各種連動に使う
export const hvnUriRoot = "https://www.cityheaven.net/tochigi/A0901/A090101/tulipgirls"

// ヘブンブログパーツ類
export const hvnShopId = "1310021549";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=20&limitedKind=0&num=8",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=8&fontcolor=10&btncolor=6&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "チューリップ宇都宮店",
    tel:        "028-666-5415",
    openTime:   "9:00~24:00",
    zipCode:    "320-0802",
    zipAddress: "栃木県 宇都宮市 江野町 3-1",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3204.731316091878!2d139.8794156152284!3d36.56059087999862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601f67bbeac7c2f5%3A0xb1f121d0ce26c386!2z44CSMzIwLTA4MDIg5qCD5pyo55yM5a6H6YO95a6u5biC5rGf6YeO55S677yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1670553355903!5m2!1sja!2sjp",
};

export const priceChart = "/img/20240716_price.jpg"
export const priceChart0801 = "/img/20240801_price.jpg"

// 20240801 料金表タイマー関数追加 system.js側も参照
export const getPriceChart=(triggerDate)=>{

    const trigger = new Date(triggerDate)
    const now = new Date()

    /* 動作確認する時にCO消して使ってください.
    console.log("現在時刻: ")
    console.log(now)
    console.log("表示差し替え時刻: ")
    console.log(trigger)
    */

    if(now.getTime() > trigger.getTime()){

        return priceChart0801

    }
    return priceChart
}

export const areaName = "宇都宮"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",          path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",          path: "/schedule", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人一覧",        path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍一覧",        path: "/allcasts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "料金システム",     path: "/system", blank: false, img: "/img/ico/i-price.png"},
        {name: "アクセス", path: "/access", blank: false, img: "/img/ico/i-map.png"},
        {name: "写メ日記", path: `${hvnUriRoot}/diarylist`, blank: true, img: "/img/ico/i-diary.png"},
        {name: "動画一覧", path: `${hvnUriRoot}/shopmovie`, blank: true, img: "/img/ico/i-video.png"},
        {name: "口コミ",   path: `${hvnUriRoot}/reviews`, blank: true, img: "/img/ico/i-review.png"},
        {name: "ネット予約", path: `${hvnUriRoot}/A6ShopReservation/`, blank: true, img: "/img/ico/i-reserve.png"},
        {name: "女子求人", path: "https://kitakanto.qzin.jp/tulipg/?v=official", blank: true, img: "/img/ico/i-rec-women.png",
         tagForced: true, requireTag: "vanilla"},
        {name: "男子求人", path: "https://mensheaven.jp/17/tulipgirls/?gop=newc", blank: true, img: "/img/ico/i-rec-men.png"},
];

export const slideArr = [
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "",
        imgPathSp: "/img/slides/20240801_slide1.jpg", },
    },
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "Ready? そろそろ会いに来る準備始めない？",
        imgPathSp: "/img/slides/20241001_slide1.jpg", },
    },
    /*
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "", 
        imgPathSp: "/", 
        imgPathPc: "", },
    },
    */
];