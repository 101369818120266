import { useEffect, useState } from "react";
import axios from "axios";
import {Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css/bundle";

import {h2Unlinked, h2Linked, castCard, diaryArticle} from"./core/core";
import { apiRoot, hvnApi, shopInfo, areaName, diaryApiRoot, hvnUriRoot, slideArr } from "./core/defines";

import PcNav from "./components/pcNav";
import SideNav from "./components/sideNav";

import { getTimeredObject } from "./core/core"

const timeredMainVisual={
    triggerDate: "2024-04-25T00:00:00",
    defaultVal: "/img/v-main-pc.jpeg",
    overrideVal: "/img/20241001-v-main-pc.jpeg",
}

const limitedSlides=[
    {
        link: {
           blank: false,
           uri: null },
        img: {
           alt: "0(ZERO)mm接客宣言", 
           imgPathSp: "/img/slides/20240307_slide1.jpg", },
       },
       {
        link: {
           blank: false,
           uri: null },
        img: {
           alt: "ドキドキが止まらない美少女！こんな美形と奇跡の0mmへ・・・ むむ age20 T153 B83(C) W59 H80", 
           imgPathSp: "/img/slides/20230509_slide2.jpg", },
       },
       {
        link: {
           blank: false,
           uri: null },
        img: {
           alt: "ゴールデンウィーク特別企画 可愛い女の子と過ごすラブラブウィーク 期間限定4/27(土)~5/6 (月)まで 9:00OPEN！！", 
           imgPathSp: "/img/slides/20240425_slide3.jpg", },
       },
]

const timeredSlides={
    triggerDate: "2024-04-25T00:00:00",
    endDate: "2024-05-07T00:00:00",
    defaultVal: slideArr,
    overrideVal: limitedSlides,
}



// SPスライドのみ 出口3つあってキモい
function slideMaker(obj){
    if(obj.link.uri !== null){
        if(obj.link.blank !== false ){
            return <SwiperSlide><a href={obj.link.uri} target="_blank" rel="noreferrer noopner"><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></a></SwiperSlide> }
            
        else{
            return <SwiperSlide><a href={obj.link.uri}><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></a></SwiperSlide> }
    }

    else{
        return <SwiperSlide><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></SwiperSlide> }
}


function Home(){
    const [casts,   setSchedule]  = useState([]);
    const [newface, setNewface]   = useState([]);

    useEffect(()=>{
        const fetchSchedule = () =>{
            axios({
            method: "get",
            url: `${apiRoot}/schedule.php`
            })
            .then((res) => {
                console.log("success");
                //console.log(res.data);
                // setSchedule(data=res.data);
                // console.log(res.data);
                setSchedule(res.data);
            })
            .catch(err =>{
                console.log("err", err);
            });
        }
        const fetchNewface = () =>{
            axios({
            method: "get",
            url: `${apiRoot}/newface.php?m=top`
            })
            .then((res) => {
                console.log("success");
                //console.log(res.data);
                // console.log(res.data);
                setNewface(res.data);
                // console.log(res.data)
            })
            .catch(err =>{
                console.log("err", err);
            });
        }

        fetchSchedule();
        fetchNewface();
},[]);

    return(
        <>
            <header>
                <div id="sp-header" className="sp txt-center">
                    <p className="title">{areaName}のソープランド【{ shopInfo.name }】</p>
                    <div className="info">
                        <address>TEL { shopInfo.tel }</address>
                        <p>営業時間 { shopInfo.openTime }<br />当日電話受付時間: 6:00~</p>
                    </div>
                </div>
                <p className="pc">
                    <a href="https://www.cityheaven.net/tochigi/A0901/A090101/tulipgirls/reviews/?of=y" target="_blank" rel="noreferrer noopner">
                        <img src={getTimeredObject(timeredMainVisual)} />
                    </a>
                </p>
                <PcNav />
            </header>
            <div className="container bg-w-alpha">
                <section id="home" className="main-colmn">
                    <section>
                        <Swiper
                            modules={[Autoplay]}
                            loop={true}
                            autoplay={true}
                            >
                                {getTimeredObject(timeredSlides).map((s)=>slideMaker(s))}
                        </Swiper>
                    </section>
                    <article class="schedule block">
                        {h2Linked("本日の出勤", "出勤一覧", "/schedule")}
                        <ul class="wrapper-sc">
                            {casts.map((c) => 
                                <li className="card-cast" key={c.c_id}>
                                    {castCard(c)}
                                </li>
                            )}
                        </ul>
                    </article>
                    <div className="pc">
                        <article id="home-hvn-diary" className="block">
                            {h2Unlinked("写メ日記")}
                            <iframe src={hvnApi.Diary}></iframe>
                            <a className="btn" href={ hvnUriRoot+ "/diarylist"}
                                target="_blank" rel="noreferrer noopner">一覧はこちら!</a>
                            
                        </article>
                        <article id="hvn-videos" className="wrappar-hvn-video block">
                            {h2Unlinked("MOVIE")}
                            <iframe src={hvnApi.Video} />
                        </article>
                    </div>
                    <article className="newface block">
                        {h2Linked("新人", "新人一覧", "/newface")}
                        <Swiper
                            modules={[Autoplay]}
                            autoplay={true}
                            loop={true}
                            
                            slidesPerView={1.2}
                            centeredSlides={true}
                            spaceBetween={5}

                            breakpoints={{ 768:{slidesPerView:3},}}
                            >
                            {newface.map((el)=>
                                <SwiperSlide
                                    className="card-cast txt-center"
                                    key={el.C_id}>
                                        {castCard(el)}
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </article>
                    <article className="pc">
                        {h2Unlinked("ACCESS")}
                        <iframe className="iframe-map" src={shopInfo.gmapUri}></iframe>
                    </article>
                </section>
                <SideNav />
            </div>
        </>
    );
}

export default Home;